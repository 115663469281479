#lraCalendarUser {
	width: 400px;
	margin-bottom: 20px;
	padding: 0.5rem;
	justify-content: space-between;
	align-items: center;
	border: 1px solid getcolor('border');
	background-color: getcolor('light');

	.doku-download {
		a {
			color: getcolor('secondary');
		}
	}

	div {
		padding-right: 10px;
	}
}