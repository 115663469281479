
.AjaxSelect-result {
	display: none;
	max-height: 500px;
	overflow-y: auto;
	box-shadow: 5px 10px 8px #888888;

	&.visible {
		display: block;
		box-sizing: border-box !important;
		background-color: getcolor('white');
		padding: 3px;
		border: 1px solid getcolor('border');
		position: absolute;
		width: 50%;
		transition: 1s all;
		z-index: 1000000;
	}

	.AjaxSelect-result-row {
		display: flex;
		flex-direction: row;
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 5px;
		border-bottom: 1px solid getcolor('border');
		align-items: center;

		img {
			width: 50px;
			height: 50px;
			object-fit: scale-down;
			margin-right: 50px;
		}

		div {
			line-height: 0;
		}

		&:hover {
			background-color: getcolor('primary');
			color: white;
		}
	}
}