.content {
	transition: 1s all;

	.login {
		height: 50vh;
		margin-right: 20%;
		margin-left: 20%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.login-img {
			width: 50%;
			margin-left: 25%;
		}

		button {
			width: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.5s all;

			&:hover {
				background: getcolor('success');
				border-color: getcolor('success');
			}
		}


		.error-msg {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: red;
			margin-top: 1rem;

			p {
				margin-bottom: 0.50rem;
			}
		}
	}

	a {
		color: getcolor('font');
	}


}
