@keyframes spinn {
	from { transform: rotate(0deg); }
	to	 { transform: rotate(360deg);}
}

#spinner {
	position: absolute;
	z-index: 1000000;
	top: 0%;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;

	&.visible {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bolder;
	}

	span {
		animation: spinn infinite 1s;
		font-size: 50px !important;
		color: getcolor('secondary');
	}
}