.NosBtn {
	display: flex;
	font-weight: 400;
	color: getcolor('white');
	text-align: center;
	cursor: pointer;
	transition: 0.50s all;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	align-items: center;
	//border-color: #6c757d;

	&.create {
		background-color: getcolor('primary');
		padding: 5px;
		box-shadow: getshadow('default');
		position: absolute;
		top: 19px;
		right: 18px;
		border-radius: 100%;

		&:hover {
			background-color: getcolor('success');
		}

		i {
			font-size: 52px;
		}
	}


	&.create_small {
		background-color: getcolor('primary');
		padding: 5px;
		box-shadow: getshadow('default');
		position: absolute;
		top: 10px;
		right: 10px;
		border-radius: 100%;

		&:hover {
			background-color: getcolor('success');
		}

		i {
			font-size: 20px;
		}
	}

	&.save {
		background-color: getcolor('primary');
		position: absolute;
		bottom: 13px;
		right: 35px;

		&:hover {
			background-color: getcolor('success');
		}

		@include mediaquery("large") {
			bottom: 10%;
		}
	}

	&.edit {
		color: getcolor('primary');
		//border-color: #6c757d;

		&:hover {
			color: getcolor('white');
			background-color: getcolor('primary');
		}
	}

	&.primary {
		background-color: getcolor('primary');

		&:hover {
			background-color: #0069d9;
		}
	}

	&.secondary {
		background-color: getcolor('secondary');

		&:hover {
			background-color: getcolor('dark');
		}
	}

	&.danger {
		color: getcolor('danger');

		&:hover {
			color: getcolor('white');
			background-color: #c82333;
		}
	}

	&.disabled {
		border: 1px solid transparent;
		border-color: rgba(0, 0, 0, .125) !important;
		background-color: transparent !important;
		color: rgba(0, 0, 0, .125);
		cursor: auto;
	}

	&.close {
		color: getcolor('dark');

		&:hover {
			color: getcolor('danger');
		}
	}

	&.normal {
		color: getcolor('secondary');

		&:hover {
			color: getcolor('dark');
		}
	}

	&.cancel {
		position: absolute;
		bottom: 21px;
		left: 35px;
		background-color: getcolor('secondary');

		&:hover {
			background-color: getcolor('dark');
		}
	}

}