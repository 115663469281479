// todo: wenn funktinoiert, dann bitte Farb Variablen in Datei variables/color.scss
$border-color: #000;


// todo: icons in "variables/icons.scss" auslagern


// todo: ENDE


.lraApp {
	/// @info
	/// Farbwerte werden NUR mit "getcolor('keyword')" benutzt
	/// z-index Werte werden NUR mit "zindex('keyword')" benutzt
	/// mediaquerys werden NUR mit @include mediaquery('keyword')  

	@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

	// variables
	@import 'abstracts/config-backend';

	@import 'abstracts/mixins';

	// backend components
	@import 'vue_components/backends/login';
	@import 'vue_components/backends/lraApp';
	@import 'vue_components/backends/AjaxSelect';
	@import 'vue_components/backends/AjaxTable';
	@import 'vue_components/backends/NosBtn';
	@import 'vue_components/backends/NosSwitch';
	@import 'vue_components/backends/HoverInfo';
	@import 'vue_components/backends/MediaSelectPopup';
	@import 'vue_components/backends/MainNav';
	@import 'vue_components/backends/Popup';
	@import 'vue_components/backends/UserMenu';
	@import 'vue_components/backends/infocard';
	@import 'vue_components/backends/Spinner';
	@import 'vue_components/backends/MediaSelect';
	@import 'vue_components/backends/NosNotice';
	@import 'vue_components/backends/EditEvent';
	@import 'vue_components/backends/EventDate';
	@import 'vue_components/backends/forms';
	@import 'vue_components/backends/MarkSeen';
	@import 'vue_components/backends/Required';
	@import 'vue_components/backends/XlsUploadPopup';

	@include lraApp;


	@import './shame.scss';

	* {
		box-sizing: border-box !important;
	}


	.border {
		border: 1px solid black;
	}

	.content {
		width: 100%;
		min-height: 375px;
	}


	.container {
		width: 50%;

		&-fluid {
			width: 100%;
		}

		margin: auto;
		display: flex;
		padding: 1.25rem;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		flex-direction: column;
	}


	// card
	.card {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		border: 1px solid rgba(0, 0, 0, .125);
		border-radius: .25rem;
		margin-bottom: 1rem;

		&.card-shadow {
			box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
		}

		.card-head {
			display: flex;
			flex-direction: row;
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			justify-content: flex-end;
			flex: 1 1 auto;
			padding: 1.25rem;
			padding-bottom: 0;
			position: relative;

			h3 {
				margin: 0;
			}
		}

		.card-body {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			padding: 1.25rem;
			display: flex;
			position: relative;

			.card-title {
				margin-bottom: .75rem;
			}

			p {
				margin-top: 0;
				margin-bottom: 1rem;
			}
		}
	}

	.clickable {
		user-select: none;
		cursor: pointer;
	}

	// Hilfsklassen
	.no {
		&-margin {
			margin: 0 !important;

			&-bottom {
				margin-bottom: 0 !important;
			}

			&-top {
				margin-top: 0 !important;
			}

			&-left {
				margin-left: 0 !important
			}

			&-right {
				margin-right: 0 !important
			}
		}

		&-padding {
			padding: 0 !important;

			&-bottom {
				padding-bottom: 0 !important;
			}

			&-top {
				padding-top: 0 !important;
			}

			&-left {
				padding-left: 0 !important
			}

			&-right {
				padding-right: 0 !important
			}
		}

		&-border {
			border: none !important;
		}
	}

	.flex {
		display: flex;

		&-start {
			display: flex;
			justify-content: flex-start !important;
		}

		&-end {
			display: flex;
			justify-content: flex-end !important;
		}

		&-between {
			display: flex;
			justify-content: space-between !important;
		}

		&-evenly {
			display: flex;
			justify-content: space-evenly !important;
		}

		&-around {
			display: flex;
			justify-content: space-around !important;
		}

		&-direction {
			display: flex;

			&-row {
				flex-direction: row !important;
			}

			&-col {
				flex-direction: column !important;
			}
		}
	}

	label {
		display: inline-block;
		margin-bottom: .5rem;
		font-size: 0.9rem;
	}

	#media-select-popup-event {
		position: absolute !important;
		top: 0 !important;
		left: 50% !important;
		z-index: 99999999999999999999999999999999 !important;
	}

	.success {
		color: getcolor('success');
	}

	.radioPair {
		display: flex;

		> input {
			width: initial;
			margin-right: 10px;
		}
	}

	.highlight-row {
		&:hover {
			background: getcolor('lightgrey');
		}
	}

	.checkboxDisabled {
		opacity: 0.25;

		.slider {
			cursor: not-allowed;
		}
	}

	input[type="checkbox"] {
		width: initial;
		heigth: initial;
	}

}