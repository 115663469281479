// ck editor
.ck {
	height: 250px;

	li {
		margin-left: 20px;
	}
}

div#price[contenteditable="false"] {
	background-color: #6c757d94;
}


// formcontrol
input, textarea, .form-control, .ck, .ck-editor__editable_inline {
	display: block;
	width: 100%;
	height: 40px;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	// transition: all 1s;
	// background: #E0E0E0;
	//background: #F5F5F5 !important;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .38) !important;
	border-radius: 4px 4px 4px 4px !important;
	//border-bottom: 1px solid rgba(0,0,0,.42);
	box-sizing: border-box;

	&:hover {
		border-color: black !important;
		color: black;
	}

	&:focus {
		color: black;
		//background-color: #DBDBDB !important;
		border: 2px solid getcolor('primary') !important;
		outline: 0;
	}

	&:disabled {
		background-color: #6c757d94;
	}
}


.form-group {
	flex-grow: 1;
	margin-left: 5px;
	margin-right: 5px;
	min-width: 231px;
	@include mediaquery("large") {
		flex-grow: 1 !important;
		margin-left: 5px !important;
		margin-right: 5px !important;
		min-width: 231px !important;
	}

	.helpertext {
		font-size: small;
		padding-left: 0;
		color: getcolor('secondary');
		display: block;
	}
}