.xls-success-msg {
	color: getcolor('success');
}

.xls-error-msg {
	color: getcolor('danger');
}

.xls-table {
	width: 100%;
	max-height: 490px;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	border: 1px solid getcolor('border');
	overflow: auto;

	> div {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		border-bottom: 1px solid getcolor('border');

		&:first-child {
			position: sticky;
			top: 0;
			align-self: flex-start;
			background-color: getcolor('border');

			> div {
				border-right: 1px solid getcolor('dark');

				&:last-child {
					border-right: none;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}

		> div {
			border-right: 1px solid getcolor('border');
			word-wrap: anywhere;
			padding: 2px;
			width: inherit;

			&:last-child {
				border-right: none;
			}

			&.hasError {
				background-color: getcolor('danger');
			}
		}
	}
}