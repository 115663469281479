.infocard {
    position: relative;
    background: getcolor('white');
    padding: 10px;
    border: 1px solid getcolor('border');
    border-radius: getradius('default');
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0,0,0,.12);
    margin: 5px 5px 5px 5px;
    flex-shrink: 1;
    flex-grow: 1;
    padding-top: 50px;
    margin-top: 1.5rem;
    min-width: 200px;
    label.info-titel {
        position: absolute;
        top: 10px;
        left:10px;
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: larger !important;
    }
    &.date-ticket {
        transition: 0.5s all;
        background-color: #E0E0D8;
        &:hover {
            border-color: getcolor('primary');
        }
    }
}