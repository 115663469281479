#EditEvent {

	.form-container {
		width: 100%;
		height: 100%;
		margin: auto;
		display: flex;
		padding: 10px;
		flex-wrap: wrap;
		overflow: auto;


		.form-item {
			transition: all 1s;
			width: 500px !important;
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 10px;
			display: flex;
			flex-wrap: wrap;
			flex-grow: 1;
			@include mediaquery("large") {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}

			.form-group {
				flex-grow: 1;
				margin-left: 5px;
				margin-right: 5px;
				min-width: 231px;
				margin-bottom: 1.5rem;
				@include mediaquery("large") {
					flex-grow: 1 !important;
					margin-left: 5px !important;
					margin-right: 5px !important;
					min-width: 231px !important;
				}

				.helpertext {
					font-size: small;
					padding-left: 0;
					color: getcolor('secondary');
					display: block;
				}
			}
		}

		.form-item-row {
			width: 100% !important;
			display: flex;
			flex-wrap: wrap;
			flex-grow: 1;
		}

		.EventDate {
			flex-grow: 1 !important;
			justify-content: unset !important;
			// flex-basis: 747px;
			@include mediaquery("small") {
				margin-left: auto;
				margin-right: auto;
			}

			&.form-item, .form-group {
				min-width: unset !important;
			}
		}
	}


}


 


  