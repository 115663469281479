@keyframes fade-slide-down {
    from {
        opacity: 0;
        top: 70px;
    }
    to {
        opacity: 1;
        top: 85px;
    }
}

.NosNotice {
    transition: all 3.5s;
    animation: fade-slide-down 2s forwards;
    position: absolute;
    display:flex; 
    flex-direction: column;
    width: 300px;
    border: 1px solid getcolor('border');
    border-radius: getradius('default');
    margin-left: auto;
    margin-right: 53px;
    overflow: hidden;
    box-shadow: getshadow('default');
    &.error {
        border-color: #f09898;
        .head { background-color: getcolor('danger'); }
        .body { background-color: #f3beb8; }
    }
    &.info {
        border-color: getcolor('info');
        .head { background-color: getcolor('info'); }
        .body { background-color: #c0daf8 }
    }
    &.success {
        border-color: getcolor('success');
        .head { background-color: getcolor('success'); }
        .body { background-color: #c0f08f }
    }
    
    .head {
        top:0;
        width: 100%;
        padding: 5px;
        color: white;
        font-weight: bold;
        background-color: getcolor('secondary');
    }

    .body {
        color: black;
        display: flex;
        padding: 10px;
        height: 100%;
    }
}