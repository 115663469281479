
.mark-seen {
	color: getcolor('secondary');
	display: block;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	border-radius: .25rem;
	cursor: pointer;
	user-select: none;
	width: 53px;

	&:hover {
		color: getcolor('white');
		background-color: getcolor('secondary');
	}
}

.mark-unseen {
	color: getcolor('secondary');
	display: block;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	border-radius: .25rem;
	cursor: pointer;
	user-select: none;
	width: 53px;

	&:hover {
		color: getcolor('white');
		background-color: getcolor('secondary');
	}
}