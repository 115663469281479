.EventDate {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .infocard {
        background-color: ghostwhite;
    }

    .form-group { 
        flex-grow: 1;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 231px;
    }
}