
.row-grey {
	background: getcolor('lightgrey');
}

.ajaxTable-table {
	border-bottom: 3px solid rgba(0, 0, 0, .125);
	height: 100%;
	margin-bottom: 50px;

	&.releases {
		min-width: 1500px;
	}

	.no-result {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 200px;
	}
}

.ajaxTable-search {
	position: absolute;
	top: 20px;
	right: 250px;
	width: 30%;
}

.ajaxTable-wrapper {

	height: auto;

	&.releases {
		overflow: auto;
	}

	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 11px;
		border-bottom: 3px solid rgba(0, 0, 0, .125);
		border-radius: .25rem;
	}
}

.ajaxTable-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 5px 0 5px 0;
	align-items: center;
	// box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	// border: 1px solid rgba(0, 0, 0, .125);
	border-radius: .25rem;
	margin-bottom: 11px;
	transition: all 1s;
	//background-color: aliceblue;
	border-bottom: 1px solid rgba(0, 0, 0, .125);

	&.header-row {
		border-bottom: 4px solid rgba(0, 0, 0, .125);
		transition: none;
	}

	&:last-child {
		border: none !important;
	}

	.col-row {
		width: 89%;
		display: inherit;
		flex-direction: row;
		align-items: center;

		.field {
			margin-right: 25px;
		}
	}

	div {
		justify-content: left;
		padding-right: 5px;
		padding-left: 5px;
	}

	.ajaxTable-image {
		width: 50px;
		height: 50px;
		object-fit: contain;
	}


}

.ajxaTable-pagination {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	position: absolute;
	width: 45%;
	z-index: 1;
	bottom: 7px;
	left: 25%;
}

.ajaxTable-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 20;
	background: rgba(0, 0, 0, 0.5);
}

.searchBreadcrumb {
	font-size: small;
	color: getcolor('secondary');
}

.fiftyfifty {
	> div {
		width: 50%;
	}
}


