@keyframes slide-fade-down {
	from {
		opacity: 0;
		top: 40%;
	}
	to {
		opacity: 1;
		top: 56%;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
		top: 56%;
	}
	to {
		opacity: 0;
		top: 40%;
	}
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 20;
	background: rgba(0, 0, 0, 0.6);

}


.popup-wrapper {
	animation: slide-fade-down 0.5s;
	position: absolute;
	width: 50%;
	height: 80%;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: .25rem;
	padding: 2rem;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
	z-index: 10000;
	background: #f1f1f1;

	@include mediaquery("large") {
		width: 100vw !important;
		height: 100vh !important;
	}
	@include mediaquery("small") {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.popup-wrapper {
		z-index: 1;
		height: 669px;

		#toggle {
			display: none;
		}
	}

	&.fullscreen {
		width: 95%;
		height: 85%;
	}

	&:not(.fullscreen) {
		.form-group {
			flex-grow: 1 !important;
			margin-left: 5px !important;
			margin-right: 5px !important;
			min-width: 231px !important;
		}

		.EventDate {
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	nav {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.popup-body {
		overflow: auto;
		height: 88% !important;
		padding-top: 10px;
	}
}
