@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
$font-family-primary: 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
$font-family-secondary: 'Montserrat', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

$colors: (
    "primary":     #007bff,
    "secondary":   #6c757d,
    "font":        #5b5777,
    "lightgrey":   #ced4da,
    "dark":        #343a40,
    "light":       #f8f9fa,
    "white":       white,
    "success":     #28a745,
    "greenyellow": greenyellow,
    "info":        #17a2b8,
    "warning":     #ffc107,
    "danger":      #dc3545,
    "border":      #ced4da,
    "border-hover": #e9ecef,
    "formcontrol": white,
    "placeholder": #495057,
    'overlay':     rgba(0, 0, 0, 0.7),
    "text":        #5b5777,
    "popup-head":  rgba(0,0,0,.05),
);


// Noscard Config
$Noscardconfig: (
  backgroundcolor:        getcolor('white'),
  primarycolor:           getcolor('primary'),
  iconcolor:              getcolor('primary'),
  fontcolor:              getcolor('font'),
  titleColor:             getcolor('dark'),
  bordercolor:            getcolor('border'),
  borderColorHover:       getcolor('primary'),
  buttonBgColor:          getcolor('white'),
  buttonBgColorHover:     getcolor('primary'),
  buttonBorderColor:      getcolor('primary'),
  buttonColor:            getcolor('primary'),
  buttonColorHover:       getcolor('white'),
  buttonBorderColorHover: getcolor('primary'),
);




$breakpoints: (
  small: (max-width: 638px),
  medium: (max-width: 768px),
  large: (max-width: 992px),
  huge: (max-width: 1200px),
);

/// Responsive-Manager
/// @author Hugo Giraudel
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin mediaquery($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  }
  @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}


$z-indexes: (
  dropdown:       1000,
  sticky:         1020,
  fixed:          1030,
  popup-overlay:  1040,
  popup:          1050,
  popover:        1060,
  tooltip:        1070,
);

$shadows: (
    default: 0 0.5rem 1rem rgba(0, 0, 0, 0.15),
    popup: 5px 10px 8px #888888,
);

$radien: (
  filter: 1rem,
  default: 0.25rem,
);

