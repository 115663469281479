#MainNav {
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
	overflow: auto;

	.tab {
		user-select: none;
		text-align: center;
		padding: 0.4rem;
		padding-bottom: 0.25rem;
		border: 1px solid getcolor('white');
		border-bottom: 1px solid getcolor('border');
		border-top-left-radius: getradius('default');
		border-top-right-radius: getradius('default');
		cursor: pointer;
		color: getcolor('font');

		&:hover {
			border-color: #e9ecef #e9ecef #dee2e6;
		}

		&.active {
			border: 1px solid getcolor('border');
			border-bottom: none;
			background: getcolor('white');
			color: black;

			&:hover {
				background: getcolor('white');
			}
		}
	}
}