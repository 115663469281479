.helpIcon-icon {
	cursor: pointer;
	user-select: none;
}

.helpIcon-text {
	display: none;
	position: absolute;
	background: #ffffff;
	padding: 10px;
	border: 1px solid #000000;
	border-radius: .25rem;

	&.show {
		display: block;
	}
}