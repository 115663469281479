// Toggle Switch
// The switch - the box around the slider
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	margin-left: 1rem;
	margin-right: 1rem;
}

// Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

// The slider
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: getcolor('danger');
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: getcolor('dark');

	&:before {
		background-color: getcolor('success');
	}

	&.danger {
		background-color: getcolor('danger');
	}

	&.warning {
		background-color: getcolor('warning');
	}
}

input:focus + .slider {
	box-shadow: 0 0 1px getcolor('dark');
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

// Rounded sliders
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}