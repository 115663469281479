.MediaSelect {

	.default {
		width: 250px;

		.title {
			border-bottom: 1px solid getcolor('border');
			padding-bottom: 5px;
		}

		.title {
			border-bottom: 1px solid getcolor('border');
			padding-bottom: 5px;
		}
	}

	.mediaSelect-row {
		margin-bottom: 5px;
		margin-top: 5px;
		height: 100px;
		width: 100%;
		justify-content: space-between;

		.img {
			width: 152px;
			height: 100px;
			object-fit: scale-down;
		}

		.title {
			width: 200px;
			overflow: auto;
			text-align: center;
		}
	}

	#media-select-popup-event {
		position: absolute !important;
		top: 50% !important;
		left: 45% !important;
		z-index: 99999999999999999999999999999999 !important;
		transform: translate(-50, -50%) !important;
		height: 685px;
		width: 70vw;
	}

	.MediaSelectPopup {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.image-auswahl {
			border: 1px solid getcolor('border');
			align-content: flex-start;
			height: 80%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			overflow: auto;

			.image-container {
				cursor: pointer;
				margin: 5px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				width: 100px;
				height: 140px;
				overflow: hidden;

				img {
					width: 100px;
					height: 100px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.img-pagination {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
		}
	}

}


