@mixin lraApp {
    position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	height: auto;
    min-height: 800px;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: .25rem;
	padding: 2rem;
	padding-top: 1rem; 
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
	font-family: $font-family-primary;
}