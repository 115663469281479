	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	
	ul, ol {
		margin-left: 20px;
	}

	.icon-row {
		display: flex;
		align-items: center;
	}

	.row {
		display: flex !important;
		flex-direction: row !important;
		align-items: center;
		&.start {
			justify-content: flex-start;
		}
		&.end {
			justify-content: flex-end;
		}
		&.center {
			justify-content: center;
		}
		&.space-between {
			justify-content: space-between;
		}
		&.space-around {
			justify-content: space-around;
		}
		&.space-evenly {
			justify-content: space-evenly;
		}
		&.wrap {
			flex-wrap: wrap;
		}
		&.nowrap {
			flex-wrap: nowrap;
		}
		&.items-center {
			align-items: center !important;
		}
	}

	.flex-item {
		&.shrink {
			flex-shrink: 1;
		}
		&.noshrink{
			flex-shrink: 0;
		}
		&.grow {
			flex-grow: 1;
		}
		&.nogrow {
			flex-grow: 0;
		}
		&.align-stretch {
			align-self: stretch;
		}
		&.reverse {
			flex-direction: row-reverse;
		}
	}

	.column {
		display: flex;
		flex-direction: column;
		&.start {
			justify-content: flex-start;
		}
		&.end {
			justify-content: flex-end;
		}
		&.center {
			justify-content: center;
		}
		&.space-between {
			justify-content: space-between;
		}
		&.space-around {
			justify-content: space-around;
		}
		&.space-evenly {
			justify-content: space-evenly;
		}
		&.items-center {
			align-items: center;
		}
		&.reverse {
			flex-direction: column-reverse;
		}
	}

	.container-full {
		width: 100%;
	}

	a {
		text-decoration-line: none !important;
	}

	.w {
		&-100 { width: 100% !important; }
		&-50 { width: 50% !important; }
		&-25 { width: 25% !important; }
	}

	.text-center {
		text-align: center;
	}

	.hover-noshadow {
		&:hover {
			box-shadow: unset !important;
		}
	}


	





